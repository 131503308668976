import ResourceBatch from 'js/resource_loader/ResourceBatch';

let loader = document.querySelector('.loader');
let progress_bar = document.querySelector('.loader__progress-bar-fill');
let processing = document.querySelector('.processing');

console.log('Developed by OHZI Interactive | https://ohzi.io')

module.exports = (parameters) => {
    const ViewApi = parameters.ViewApi;

    let batch = new ResourceBatch();

    // for(let i=0; i< 301; i++)
    // {
    // 	let number = "0" + i;

    // 	if(number < 100)
    // 		number = "00" + i;
    // 	if(number < 10)
    // 		number = "000" + i;

    // 	batch.add_obj("metaball"+i,    		"models/sequence/metaballs_" + number+".obj");
    // 	console.log("asd", i);
    // }

    batch.add_gltf("metaball",        "models/cell.glb");
  	// batch.add_gltf("metaball",    		"models/splash_3.glb");

    batch.add_texture("view_cube_png",    			"models/view_cube.png");
    batch.add_texture("view_cube_base_png",     "models/view_cube_base.png");
    batch.add_texture("matcap", "textures/matcap.png");
    batch.add_dae("view_cube",    							"models/view_cube.dae");



    batch.load();

    let check_resource_loading = ()=>{
      progress_bar.style.width = `${batch.get_progress() * 100}%`;

      if (batch.get_progress() >= 1)
      {
        processing.classList.remove('hidden');
      }

      if(batch.loading_finished)
      {
        if(batch.has_errors)
        {
          batch.print_errors();
        }
        else
        {
          loader.classList.add('hidden');
          processing.classList.add('hidden');

          ViewApi.resource_loading_completed();
          ViewApi.start();
        }
      }
      else
      {
        setTimeout(check_resource_loading, 200);
      }
    }
    check_resource_loading();

};
